<template>
  <ul class="tree">
    <li v-for="node in nodes" :key="node.id" :data-reference="node.reference">
      <div
        @click="clickEvent(node)"
        v-show="isVisible(node)"
        :class="{ active: node.selected }"
        :ref="node.reference"
      >
        <b-icon :icon="getIcon(node)"></b-icon>
        {{ node.text }}
      </div>
      <tree-node
        v-show="node.nodes && node.opened && isVisible(node)"
        :options="options"
        :nodes="node.nodes"
        @leafClicked="leafClicked"
      ></tree-node>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["nodes", "options"],
  name: "tree-node",
  methods: {
    leafClicked(node) {
      // Pass to parent
      this.$emit("leafClicked", node);
    },
    clickEvent(node) {
      if (node.nodes) {
        node.opened = !node.opened;
        this.$forceUpdate();
      } else {
        this.$emit("leafClicked", node);
      }
    },
    getIcon(node) {
      if (node.nodes) {
        return node.opened ? "folder2-open" : "folder2";
      }

      return "file-earmark-text";
    },
    isVisible(node) {
      return !node.hide;
    },
  },
};
</script>
