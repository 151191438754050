<template>
  <div class="d-flex flex-column col-lg-10">
    <div class="row">
      <div class="col-5" style="height: 80vh">
        <overlay-scrollbars
          :options="{ overflowBehavior: { x: 'hidden' } }"
          ref="treeScroller"
        >
          <admx-tree-view
            v-if="setId"
            :set-id="setId"
            :policy-class="policyClass"
            :language-code="languageCode"
            @nodeSelected="scrollTreeView"
          >
          </admx-tree-view>
        </overlay-scrollbars>
      </div>
      <div class="col-7">
        <div v-if="!policyId">
          <h4>ADMX {{ policyClass }} Policies for {{ setId }}</h4>
          <div class="col-6">
            <p>
              This page shows all the policies you will find in the Windows
              Group Policy editor for this set. You can navigate policies using
              the tree view on the left side, or to view different aspects of
              the set using the navgiation on the top.
            </p>
            <p>Select a policy first</p>
          </div>
        </div>
        <admx-policy-view
          v-else
          :set-id="setId"
          :policy-class="policyClass"
          :language-code="languageCode"
          :policy-id="policyId"
        >
        </admx-policy-view>
      </div>
    </div>
  </div>
</template>

<script>
import AdmxSetTreeView from "../components/tree-view/TreeView.vue";
import AdmxPolicyView from "../components/AdmxPolicyView.vue";

export default {
  components: {
    "admx-tree-view": AdmxSetTreeView,
    "admx-policy-view": AdmxPolicyView,
  },
  computed: {
    setId() {
      return this.$route.params.setId;
    },
    policyClass() {
      return this.$route.params.policyClass;
    },
    languageCode() {
      return this.$route.params.languageCode;
    },
    policyId() {
      return this.$route.params.policyId;
    },
  },
  methods: {
    scrollTreeView(node) {
      // On the next complete rendering, scroll to the selected node
      this.$nextTick(function () {
        const element = document.querySelector(
          "[data-reference='" + node.reference + "']"
        );

        const osInstance = this.$refs.treeScroller.osInstance();
        osInstance.scroll({
          el: element,
          scroll: "ifneeded",
          block: "center",
        });
      });
    },
  },
};
</script>
