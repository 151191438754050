<template>
  <span
    >{{ details.value.displayString }}
    <input
      class="form-control-sm"
      type="number"
      :min="details.element.minValue"
      :max="details.element.maxValue"
      :step="details.value.incrementStep"
      :value="details.value.default"
    />
  </span>
</template>

<script>
export default {
  props: ["details"],
};
</script>
