<template>
  <div>
    <!-- <h4>{{ element.id }}</h4> -->
    <!-- <div>{{ details }}</div> -->
    <div class="card bg-white mb-3">
      <div class="card-header d-flex justify-content-between">
        <span>{{ details.displayString }}</span>
        <span class="text-muted">({{ value.kind }}) {{ value.refId }}</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col col-xl-7">
            <table class="table table-sm table-borderless">
              <tbody>
                <tr>
                  <th class="text-nowrap">Registry key</th>
                  <td class="text-break">
                    <span class="text-muted"
                      >{{ policyClass == "Machine" ? "HKLM" : "HKCU" }}\</span
                    ><span>{{ element.primaryRegistryKey }}</span>
                  </td>
                </tr>
                <tr>
                  <th>Value name</th>
                  <td>
                    <span
                      >{{ element.primaryRegistryValue
                      }}<span
                        v-if="element.registryValueIsPrefix"
                        class="text-muted font-italic"
                        >{Suffix}</span
                      ></span
                    >
                  </td>
                </tr>
                <tr>
                  <th>Value type</th>
                  <td>
                    <registry-value-kind
                      :kind="registryValueType"
                    ></registry-value-kind>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col col-xl-5">
            <table class="table table-sm table-borderless">
              <tbody>
                <tr v-if="'default' in value">
                  <th
                    title="When this policy is enabled, the following value will be chosen by default"
                  >
                    Default (when enabled)
                    <b-icon-info-circle></b-icon-info-circle>
                  </th>
                  <td v-if="defaultValue" class="text-break">
                    {{ defaultValue }}
                  </td>
                  <td v-else class="text-muted font-italic">(blank)</td>
                </tr>
                <tr v-if="'minValue' in element || 'maxValue' in element">
                  <th>Range</th>
                  <td>
                    {{ element.minValue }} ..
                    {{ element.maxValue }}
                  </td>
                </tr>
                <tr v-if="'maxLength' in element">
                  <th>Max length</th>
                  <td>{{ element.maxLength }}</td>
                </tr>
                <tr v-if="'maxStrings' in element">
                  <th>Max lines</th>
                  <td>{{ element.maxStrings }}</td>
                </tr>
                <tr v-if="'isRequired' in element">
                  <th>Required</th>
                  <td>{{ element.isRequired ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="'incrementStep' in value">
                  <th>Step</th>
                  <td>{{ value.incrementStep }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <registry-value-spec-table
          :list="element.registryValues"
          v-if="element.registryValues"
        ></registry-value-spec-table>
        <element-integer
          v-if="value.kind == 'integer'"
          :details="details"
        ></element-integer>
        <element-text-box
          v-else-if="value.kind == 'text-box'"
          :details="details"
        >
        </element-text-box>
        <element-list-box
          v-else-if="value.kind == 'list-box'"
          :details="details"
        ></element-list-box>
      </div>
    </div>
  </div>
</template>

<script>
import ElementInteger from "./Integer.vue";
import ElementTextBox from "./TextBox.vue";
import ElementListBox from "./ListBox.vue";
import RegistryValueKind from "../common/RegistryValueKind.vue";
import RegistryValueSpecTable from "../common/RegistryValueSpecTable.vue";
import * as utility from "../../utilities.js";

export default {
  components: {
    ElementInteger,
    ElementTextBox,
    ElementListBox,
    RegistryValueKind,
    RegistryValueSpecTable,
  },
  props: ["details"],
  computed: {
    value() {
      return this.details.value;
    },
    element() {
      return this.details.element;
    },
    policyClass() {
      return this.$route.params.policyClass;
    },
    registryValueType() {
      if (this.element.registryValues) {
        let uniqueValueTypes = this.element.registryValues.map(
          (x) => x.registryValueType
        );

        uniqueValueTypes = utility.distinct(uniqueValueTypes);

        return uniqueValueTypes.length == 1 ? uniqueValueTypes[0] : "unknown";
      }

      return this.element.spec.registryValueType;
    },
    defaultValue() {
      if (this.element.registryValues) {
        // Return the corresponding registryValues item
        let spec = this.element.registryValues.find(
          (x) => x.value == this.value.default
        );

        if (spec) return spec.state;
      }

      return this.value.default;
    },
  },
};
</script>
