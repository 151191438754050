<template>
  <div>
    <div class="input-group mb-3 mr-3 pr-3">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <b-icon-search
            :animation="searchLoading ? 'fade' : ''"
          ></b-icon-search>
        </span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Search..."
        v-model="searchString"
      />
    </div>
    <tree-node
      :options="options"
      :nodes="model"
      @leafClicked="leafClicked"
      ref="tree"
    ></tree-node>
  </div>
</template>

<style>
div.active {
  background-color: lightgray;
}

ul.tree {
  padding-left: 0;
}

ul.tree,
.tree ul {
  list-style: none;
}
.tree ul {
  padding-left: 15px;
}

li div {
  padding-left: 2em;
  text-indent: -2em;
}
</style>

<script>
import * as utility from "../../utilities.js";
import TreeNode from "./TreeNode.vue";

export default {
  components: {
    TreeNode,
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
    searchString: "performSearch",
  },
  methods: {
    performSearch() {
      const newSearchVersion = this.options.searchVersion + 1;
      const search = (this.searchString || "").trim();

      if (!search || search.length <= 3) {
        utility.visitor(this.model, "nodes", function (node) {
          node.hide = false;
        });
        return;
      }

      if (this.searchStringLast === search) return;

      this.searchLoading = true;

      this.$root.$api
        .getSetSearch(this.setId, this.policyClass, this.languageCode, search)
        .then((hits) => {
          if (search !== this.searchString) return;

          // Mark all hitLookup+parents as visible
          for (const policyId of hits) {
            const node = this.modelIndex[policyId];

            let parent = node;
            while (parent) {
              // Early exit if all parents are already shown
              if (parent.searchVersion == newSearchVersion) break;

              if (parent.nodes) {
                // Hide all childs, except hits
                for (const child of parent.nodes) {
                  if (child.searchVersion != newSearchVersion) {
                    child.hide = true;
                  }
                }
                // Open this parent
                parent.opened = true;
              }

              parent.hide = false;
              parent.searchVersion = newSearchVersion;
              parent = parent.parent;
            }
          }

          // Hide all roots not to be shown
          for (const root of this.model) {
            if (root.searchVersion != newSearchVersion) root.hide = true;
          }

          this.options.searchVersion = newSearchVersion;
          this.searchStringLast = search;
          this.searchLoading = false;
        });
    },
    fetchData() {
      const modelParams = {
        setId: this.setId,
        policyClass: this.policyClass,
        languageCode: this.languageCode,
      };

      // Reload the tree view, if it has changed
      if (!utility.shallowEqual(this.modelParams, modelParams)) {
        this.modelParams = modelParams;
        this.isLoading = true;

        this.$root.$api
          .getSetTree(
            modelParams.setId,
            modelParams.policyClass,
            modelParams.languageCode
          )
          .then((data) => {
            if (!utility.shallowEqual(this.modelParams, modelParams)) return;

            const lookupTable = {};

            let idx = 0;
            utility.visitor(data, "nodes", function (node) {
              if (node.nodes) {
                node.opened = false;
                for (const child of node.nodes) {
                  child.parent = node;
                }
              }
              node.id = idx++;
              node.searchVersion = 0;
              node.hide = false;
              node.selected = false;

              // Add to index
              lookupTable[node.reference] = node;
            });

            this.model = data;
            this.isLoading = false;

            Object.freeze(lookupTable);
            this.modelIndex = lookupTable;
            this.selectedRef = undefined;

            const selectedId = this.$route.params.policyId;
            this.selectByReference(selectedId);
          });
      }
    },
    leafClicked(node) {
      let params = {
        ...this.$route.params,
        policyId: node.reference,
      };

      if (node.reference == this.selectedRef) return;

      if (this.selectedRef) {
        // Unselect previous
        this.modelIndex[this.selectedRef].selected = false;
      }

      this.selectedRef = node.reference;
      node.selected = true;

      this.$router.pushSafe({ name: "admx-set-view", params });

      this.$emit("nodeSelected", node);
    },
    selectByReference(reference) {
      if (!(reference in this.modelIndex)) return;

      // Highlight selected node
      let node = this.modelIndex[reference];

      this.selectedRef = node.reference;
      node.selected = true;

      let parent = node.parent;
      while (parent) {
        parent.opened = true;
        parent = parent.parent;
      }

      this.$emit("nodeSelected", node);
    },
  },
  props: {
    setId: String,
    policyClass: String,
    languageCode: String,
  },
  data() {
    return {
      isLoading: false,
      modelParams: {},
      model: [
        {
          text: "Loading ...",
          checkable: false,
        },
      ],
      modelIndex: {},
      options: {
        searchVersion: 0,
      },
      policyId: undefined,
      searchString: "",
      searchStringLast: "",
      searchLoading: false,
      selectedRef: "",
    };
  },
};
</script>
