<template>
  <v-fragment>
    <span v-if="kind == 'reg-dword' || kind == 'reg-qword'"
      >{{ value }} ({{ valueHex }})</span
    >
    <span
      v-else-if="kind == 'reg-sz' || kind == 'reg-expand-sz'"
      class="text-monospace"
      >{{ value }}</span
    >
    <span v-else-if="kind == 'reg-multi-sz'" class="text-danger"
      >TODO multi-str {{ value }}</span
    >
    <span v-else-if="kind == 'reg-binary'">TODO binary {{ value }}</span>
    <span v-else-if="kind == 'delete'"></span>
    <span v-else>TODO {{ kind }} {{ value }}</span>
  </v-fragment>
</template>

<script>
export default {
  props: ["kind", "value"],
  computed: {
    valueHex() {
      let hexStr = this.value.toString(16);
      if (hexStr.length % 2 != 0) hexStr = "0x0" + hexStr;
      else hexStr = "0x" + hexStr;

      return hexStr;
    },
  },
};
</script>
