<template>
  <span
    >{{ details.value.displayString }}
    <input
      class="form-control-sm"
      type="text"
      v-if="!details.value.isMultiline"
      :maxlength="details.element.maxLength"
      :value="details.value.default"
    />
    <textarea
      class="form-control-sm"
      type="text"
      v-else
      :maxlength="details.element.maxLength"
      :value="details.value.default"
    />
    <span class="text-muted" v-if="details.value.suggestions"
      >This text box will suggest the following: "{{
        details.value.suggestions | join('", "')
      }}"</span
    >
  </span>
</template>

<script>
export default {
  props: ["details"],
};
</script>
