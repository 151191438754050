<template>
  <span>
    <div class="text-center text-muted font-italic">
      This is an integer input field, it allows a user to enter a numerical
      value within the range specified.
    </div></span
  >
</template>

<script>
export default {
  props: ["details"],
};
</script>
