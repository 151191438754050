<template>
  <div>
    <!-- <h4>{{ details.value.kind }}</h4> -->
    <element-integer
      v-if="details.value.kind == 'integer'"
      :details="details"
    ></element-integer>
    <element-dropdown
      v-else-if="details.value.kind == 'dropdown'"
      :details="details"
    ></element-dropdown>
    <element-text
      v-else-if="details.value.kind == 'text'"
      :details="details"
    ></element-text>
    <element-text-box
      v-else-if="details.value.kind == 'text-box'"
      :details="details"
    ></element-text-box>
    <element-list-box
      v-else-if="details.value.kind == 'list-box'"
      :details="details"
    ></element-list-box>
    <element-boolean
      v-else-if="details.value.kind == 'boolean'"
      :details="details"
    ></element-boolean>
    <span v-else
      >Unknown type, <span class="text-danger">{{ details.value.kind }}</span
      >, {{ details.value }}</span
    >
    <!-- <div class="text-muted">{{ details }}</div> -->
  </div>
</template>

<script>
import ElementInteger from "./Integer.vue";
import ElementDropdown from "./Dropdown.vue";
import ElementText from "./Text.vue";
import ElementTextBox from "./TextBox.vue";
import ElementListBox from "./ListBox.vue";
import ElementBoolean from "./Boolean.vue";

export default {
  components: {
    ElementInteger,
    ElementDropdown,
    ElementText,
    ElementTextBox,
    ElementListBox,
    ElementBoolean,
  },
  props: ["details"],
};
</script>
