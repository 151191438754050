<template>
  <span :class="{ 'text-muted': muted }">{{ displayName }}</span>
</template>

<script>
const kinds = {
  delete: "(Delete)",
  unknown: "Depends on the value",
  "reg-sz": "REG_SZ",
  "reg-multi-sz": "REG_MULTI_SZ",
  "reg-expand-sz": "REG_EXPAND_SZ",
  "reg-dword": "REG_DWORD",
  "reg-qword": "REG_QWORD",
  "reg-binary": "REG_BINARY",
};

export default {
  props: {
    kind: String,
  },
  computed: {
    displayName() {
      return kinds[this.kind];
    },
    muted() {
      return this.kind == "delete" || this.kind == "unknown";
    },
  },
};
</script>
