<template>
  <div class="mb-4">
    <div class="row">
      <div class="col col-md-6">
        <div>
          <span class="font-weight-bold">Other ADMX sets:</span>
          <router-link
            v-for="item in value.availableVersions"
            :key="item.setId"
            :to="{
              name: 'admx-set-view',
              params: { ...$route.params, setId: item.setId },
            }"
          >
            <span class="badge badge-light">{{ item.setId }}</span></router-link
          >
        </div>
      </div>
      <div class="col col-md-6">
        <div>
          <span class="font-weight-bold">Supported on:</span>
          {{ value.supportedOnDisplayName }}
          <a
            href="#"
            class="ml-1"
            @click="showSupportedProducts = !showSupportedProducts"
          >
            <b-icon
              :icon="showSupportedProducts ? 'info-circle-fill' : 'info-circle'"
            ></b-icon>
          </a>
        </div>
      </div>
    </div>
    <b-collapse
      v-model="showSupportedProducts"
      ref="supportedOnDetails"
      class="mt-2"
    >
      <span class="font-weight-bold">Supported on:</span>
      <span
        v-for="product in value.supportedOnProducts"
        :key="product.Id"
        class="badge badge-info mx-1"
        >{{ product.displayName }}</span
      >
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      showSupportedProducts: false,
    };
  },
  watch: {
    $refs: "doWork",
  },
  methods: {
    doWork() {
      console.log(this.$refs.supportedOnProducts);
    },
  },
  computed: {
    isShown() {
      console.log(this.$refs.supportedOnProducts);
      return this.$refs.supportedOnProducts || false;
    },
  },
};
</script>
