<template>
  <div>
    <div v-if="isLoading">Loading ...</div>
    <div v-if="!isLoading">
      <div id="policyView" style="">
        <div class="d-flex justify-content-between align-items-baseline">
          <ul class="minicrumbs p-0 m-0">
            <li v-for="parent in model.parents" :key="parent.reference">
              {{ parent.displayName }}
            </li>
          </ul>
          <span>{{ model.policyFileName }}</span>
        </div>
        <h3>{{ model.displayName }}</h3>
      </div>

      <div class="mt-4">
        <admx-support-block
          v-model="model.supportedOnDetails"
        ></admx-support-block>
      </div>

      <div class="mt-4">
        <h5 class="font-weight-bold">Description</h5>
        <multi-line-text :value="model.description"></multi-line-text>
      </div>

      <div class="mt-4">
        <h5 class="font-weight-bold">Enabled / Disabled</h5>

        <registry-value-spec-table
          :list="model.enabledDisabled"
          v-if="model.enabledDisabled"
        ></registry-value-spec-table>

        <span v-if="!model.enabledDisabled" class="text-muted"
          >This policy does not set any specific values when enabled or
          disabled. See below for additional values.</span
        >
      </div>

      <div class="mt-4">
        <h5 class="font-weight-bold">GPO view</h5>

        <span class="text-muted" v-if="!model.presentation"
          >No special presentation or settings are available for this
          policy</span
        >
        <template v-else>
          <presentation
            v-for="(item, index) in model.presentation"
            :key="index"
            :details="{
              value: item,
              element: getElementById(item.refId),
            }"
          ></presentation>
        </template>
      </div>

      <div class="mt-4" v-if="model.elements">
        <h5 class="font-weight-bold">Value details</h5>

        <admx-value
          v-for="(item, index) in model.elements"
          :key="index"
          :details="{
            displayString: getDisplayString(getPresentationById(item.id)),
            value: getPresentationById(item.id),
            element: item,
          }"
        >
        </admx-value>
      </div>
    </div>
  </div>
</template>

<style>
/* Mini breadcrumb style */
ul.minicrumbs {
  list-style: none;
}
ul.minicrumbs li {
  display: inline;
}
ul.minicrumbs li + li:before {
  content: "/\00a0";
}
</style>

<script>
import * as utility from "../utilities.js";
import AdmxPolicySupportBlock from "./AdmxPolicySupportBlock.vue";
import RegistryValueSpecTable from "./common/RegistryValueSpecTable.vue";
import MultiLineText from "./common/MultiLineText.vue";
import PresentationItem from "./admx-elements/Index.vue";
import ValueItem from "./admx-values/Index.vue";

export default {
  components: {
    "admx-support-block": AdmxPolicySupportBlock,
    "registry-value-spec-table": RegistryValueSpecTable,
    "multi-line-text": MultiLineText,
    presentation: PresentationItem,
    "admx-value": ValueItem,
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      const modelParams = {
        setId: this.setId,
        policyClass: this.policyClass,
        languageCode: this.languageCode,
        policyId: this.policyId,
      };

      // Reload the policy view
      if (!utility.shallowEqual(this.modelParams, modelParams)) {
        this.modelParams = modelParams;
        this.isLoading = true;

        this.$root.$api
          .getSetPolicy(
            modelParams.setId,
            modelParams.policyClass,
            modelParams.languageCode,
            modelParams.policyId
          )
          .then((data) => {
            // Ensure we're getting a response for our request
            if (!utility.shallowEqual(this.modelParams, modelParams)) return;

            this.model = data;
            this.isLoading = false;
          });
      }
    },
    getDisplayString(item) {
      if (item.kind != "text-box" || item.displayString)
        return item.displayString;

      // Sometimes the text-box presentation item doesn't have a display string - we can attempt to get the one just before it, in case it's a text.
      for (const [
        index,
        presentationItem,
      ] of this.model.presentation.entries()) {
        if (presentationItem.refId != item.refId) continue;

        // Evaluate the one before
        let previousItem = this.model.presentation[index - 1];
        if (
          previousItem &&
          previousItem.kind == "text" &&
          previousItem.displayString
        )
          return previousItem.displayString;

        break;
      }

      return "Unknown";
    },
    getPresentationById(id) {
      return this.model.presentation.find((x) => x.refId === id);
    },
    getElementById(id) {
      return this.model.elements.find((x) => x.id === id);
    },
  },
  props: {
    setId: String,
    policyClass: String,
    languageCode: String,
    policyId: String,
  },
  data() {
    return {
      isLoading: false,
      model: {},
      modelParams: {},
    };
  },
};
</script>
