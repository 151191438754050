var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('div',[_vm._v("Loading ...")]):_vm._e(),(!_vm.isLoading)?_c('div',[_c('div',{attrs:{"id":"policyView"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('ul',{staticClass:"minicrumbs p-0 m-0"},_vm._l((_vm.model.parents),function(parent){return _c('li',{key:parent.reference},[_vm._v(" "+_vm._s(parent.displayName)+" ")])}),0),_c('span',[_vm._v(_vm._s(_vm.model.policyFileName))])]),_c('h3',[_vm._v(_vm._s(_vm.model.displayName))])]),_c('div',{staticClass:"mt-4"},[_c('admx-support-block',{model:{value:(_vm.model.supportedOnDetails),callback:function ($$v) {_vm.$set(_vm.model, "supportedOnDetails", $$v)},expression:"model.supportedOnDetails"}})],1),_c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Description")]),_c('multi-line-text',{attrs:{"value":_vm.model.description}})],1),_c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Enabled / Disabled")]),(_vm.model.enabledDisabled)?_c('registry-value-spec-table',{attrs:{"list":_vm.model.enabledDisabled}}):_vm._e(),(!_vm.model.enabledDisabled)?_c('span',{staticClass:"text-muted"},[_vm._v("This policy does not set any specific values when enabled or disabled. See below for additional values.")]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("GPO view")]),(!_vm.model.presentation)?_c('span',{staticClass:"text-muted"},[_vm._v("No special presentation or settings are available for this policy")]):_vm._l((_vm.model.presentation),function(item,index){return _c('presentation',{key:index,attrs:{"details":{
            value: item,
            element: _vm.getElementById(item.refId),
          }}})})],2),(_vm.model.elements)?_c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Value details")]),_vm._l((_vm.model.elements),function(item,index){return _c('admx-value',{key:index,attrs:{"details":{
          displayString: _vm.getDisplayString(_vm.getPresentationById(item.id)),
          value: _vm.getPresentationById(item.id),
          element: item,
        }}})})],2):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }