<template>
  <span
    >{{ details.value.displayString }}
    <input class="form-control-sm" type="button" value="Show..." />
  </span>
</template>

<script>
export default {
  props: ["details"],
};
</script>
