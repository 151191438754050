<template>
  <span
    >{{ details.value.displayString }}
    <select>
      <option
        v-for="item in uniqueStates"
        :key="item.state"
        :selected="item.value == details.value.default"
      >
        {{ item.state }}
      </option>
    </select>
    <!-- <div>{{ details }}</div> -->
  </span>
</template>

<script>
export default {
  props: ["details"],
  computed: {
    uniqueStates() {
      /* example with a gajillion values: win-10-2004/User/en-US/Microsoft.Policies.InternetExplorer:IZ_PolicyRestrictedSitesZoneTemplate */
      let stateMap = {};
      for (const item of this.details.element.registryValues) {
        if (!(item.state in stateMap)) {
          stateMap[item.state] = item;
        }
      }

      return stateMap;
    },
  },
};
</script>
