<template>
  <v-fragment>
    <p v-for="(text, index) in lines" :key="index">
      {{ text }}
    </p>
  </v-fragment>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    lines() {
      return this.value.split("\n").filter((x) => x);
    },
  },
};
</script>
