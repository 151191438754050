<template>
  <table class="table table-sm">
    <tbody>
      <tr>
        <th></th>
        <th>Key</th>
        <th>Value</th>
        <th>Data</th>
        <th>Type</th>
      </tr>
      <tr v-for="(value, index) in list" :key="index">
        <th>{{ value.state }}</th>
        <td class="text-break">
          <span class="text-muted"
            >{{ policyClass == "Machine" ? "HKLM" : "HKCU" }}\</span
          >{{ value.registryKey }}
        </td>
        <td>{{ value.registryValueName }}</td>
        <td>
          <registry-value
            :kind="value.registryValueType"
            :value="value.value"
          ></registry-value>
        </td>
        <td>
          <registry-value-kind
            :kind="value.registryValueType"
          ></registry-value-kind>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import RegistryValue from "./RegistryValue.vue";
import RegistryValueKind from "./RegistryValueKind.vue";

export default {
  components: {
    "registry-value": RegistryValue,
    "registry-value-kind": RegistryValueKind,
  },
  props: ["list"],
  computed: {
    policyClass() {
      return this.$route.params.policyClass;
    },
  },
};
</script>
